<!-- <div class="card">
        <div class="body pt-10 " style="height: 100%;">
            
                <div class="text-center">
                    <article>
                        <h1>Access Denied!</h1>
                        <div>
                            <p>Sorry for the inconvenience<br>You are n’t allow to accesses , Please contact Administrator for further help!</p>
                            <p>— {{shortTitle}}</p>
                        </div>
                    </article>
                    <div class="margin-top-30">
                        <button (click)="goHome()" class="btn btn-info ml-2"><i class="fa fa-home"></i><span>Home</span></button>
                    </div>
            </div>
    </div>
</div> -->

<div class="d-flex align-items-center justify-content-center min-vh-100 min-vw-100 overflow-hidden">
    <div class="d-flex flex-column align-items-center justify-content-center">
        <img [src]="logo" alt="logo" class="mb-3 mt-2" style="width: 6rem;">
        <div class="bg-gradiant">
            <div class="w-100 bg-light p-4 p-md-5 d-flex flex-column align-items-center" style="border-radius: 53px;">
                <div class="d-flex justify-content-center align-items-center bg-google rounded-circle"
                    style="height: 3.2rem; width: 3.2rem;">
                    <i class="pi pi-exclamation-circle text-2xl text-white"></i>
                </div>
                <h1 class="text-dark font-weight-bold  mb-2">Access Denied</h1>
                <div class="text-muted text-center mb-5">
                    <!-- You do not have the necessary permissions. -->
                    Sorry for the inconvenience<br>You are n’t allow to accesses , Please contact Administrator for
                    further help!
                    <br>
                    <p class="text-end mt-1">— {{shortTitle}}</p>
                </div>

                <img [src]="access_denied" alt="Error" class="mb-5" style="width: 80%;">
                <!-- <a [routerLink]="urlService.DASHBOARD_ROUTE" class="btn btn-primary" aria-label="Go to Dashboard">
                    <i class="pi pi-arrow-left"></i> Go to Dashboard
                </a> -->
                <p-button [text]="true" severity="help" icon="pi pi-arrow-left" label="Go to Dashboard"
                    [routerLink]="'/'+ urlService.DASHBOARD_ROUTE"></p-button>
            </div>
        </div>
    </div>
</div>