import { Component, OnInit } from '@angular/core';
import { AppConstant } from 'src/app/helper/class/app-constant';
import { UrlServices } from 'src/app/helper/class/url-services';
import { AuthService } from 'src/app/helper/service/auth.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {
  shortTitle = AppConstant.ORG_NAME;
  logo = AppConstant.LOGO_SRC;
  access_denied = AppConstant.ACCESS_DENIED_SVG;
  urlService = UrlServices
  constructor(private auth: AuthService) { }

  ngOnInit(): void {
  }
  goHome() {
    this.auth.redirectToDashboard();
  }
}
